<script>
import ProduitCard from '@/components/presentation/produitCard.vue'
export default {
  name: 'boutique-index',
  components: {
    ProduitCard
  },
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
  }
}
</script>

<template lang='pug'>
  .boutique-index
    .page-header(color='primary')
      .header__inner-content
        .header-title()
          //- div.secondary-title Sous-titre
          div.main-title Boutique Dokoma
        //- .header-description C'est avec des mots qu'on fait des phrases.
    .produit-cards__wrapper
      template(v-for='(n,i) in 10')
        produit-card
</template>
<style lang='sass'>
.produit-cards__wrapper
  padding: 20px
</style>
